
<template>
  <div v-if="isExport">
    <pdfFile
      :dataExport="dataExport"
      @generatedFile="generatedFile"
    />
  </div>
</template>

<script>
import pdfFile from "../../components/files/invoiceFileMock.vue";
import transactionApi from "@/api/transaction/";
export default {
  components: {
    pdfFile,
  },
  data: () => ({
    dataExport: [],
    loadingExport: false,
    isExport: false,
  }),
  async created() {
    let responseData = [];
    responseData = await transactionApi.transaction.getOne(
      this.$route.query.id
    );
    if (responseData.response_status == "SUCCESS") {
      this.dataExport = responseData.data;
      this.dataExport.isCopy = this.$route.query.isCopy == 'true' ? true : false;
      console.log('this.dataExport.isCopy',this.dataExport.isCopy);
      this.loadingExport = true;
      this.isExport = true;
    }
  },
  methods: {
    generatedFile() {
      this.isExport = false;
      window.close();
    },
  },
};
</script>